<template>
  <div style="height: 100vh;background-color: #f8f8f8;">
    <van-form @submit="onSubmit" style="padding: 1rem;margin-top: 1rem; ">
      <div>
        <span style="margin-top: 1rem;font-weight: 600;">券号 </span>
        <van-field style="border-radius: 1.5rem;margin-top: 1rem;" label-width="3rem" v-model="form.cardNo" placeholder="请输入12位卡/券码" :rules="[{ required: true }]" />

        <p style="margin-top: 1rem;font-weight: 600;">密码 </p>
        <van-field style="border-radius: 1.5rem;margin-top: 1rem;" label-width="3rem" v-model="form.cardPwd" type="password" placeholder="请输入6位密码" :rules="[{ required: true }]" />

      </div>
      <div>
        <van-button type="primary" round class="foot-btn" color="linear-gradient(to right,  #FFC585, #D59A54)" style="font-size: 1.1rem; box-shadow: 0 0 2px #D33B2E;">绑定</van-button>
      </div>
    </van-form>
    <BindGetKnow v-if="show" @closed="closeds" @sured="sured" :cardDate="cardDate"></BindGetKnow>
  </div>
</template>

<script>

import BindGetKnow from '../../components/card/BindGetKnow'
export default {
  name: '',
  data () {
    return {
      form: {
        cardNo: '',
        cardPwd: '',
        userId: '',
        ticketType: '',
        flag: false
      },
      show: false,
      cardDate: null
    };
  },
  components: { BindGetKnow },
  methods: {

    closeds () {
      this.show = false
    },
    onSubmit () {
      this.$api
        .checkBenefitCard(this.form)
        .then((res) => {
          if (res.code == 0) {
            this.cardDate = res.data
            this.show = true
          } else {
            this.$Toast(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sured () {
      this.$api
        .bindCard(this.form)
        .then((res) => {
          this.$Toast(res.msg);
          if (res.code == 0) {
            this.show = false
            localStorage.setItem('cardback', true)
            window.history.back()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted () {
    localStorage.getItem("userInfo")
      ? (this.form.userId = JSON.parse(localStorage.getItem("userInfo")).id)
      : ""
    this.form.ticketType = this.$route.query.ticketType
  }
}

</script>
<style  lang="scss" scoped>
.foot-btn {
  width: 100%;
  margin-top: 3rem;
  // margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 2rem;
}

.van-cell:after {
  border-bottom: 0;
}
</style>